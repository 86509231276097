<template>
  <div @dblclick="dblClicked">
      <template v-if="!showDatePicker">
          {{formatDate(theDate)}}
      </template>
      <template v-else>
          <a-date-picker v-model:value="theDate" format="DD-MM-YYYY" :disabled="loading"/>
          <a-button type="primary" class="ml-1" @click="updateDate" :loading="loading">Update</a-button>
          <a-button class="ml-1" @click="cancel">Cancel</a-button>
      </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import moment from "moment/moment";
import clickoutside from '../lib/clickOutside'
const url = "api.darkside-developments.com" //"darkside_api.test";
export default defineComponent({
    props: ['date', 'id'],
    data() {
        return {
            value: "",
            showDatePicker: false,
            loading: false,
        }
    },
    directives: {
        clickoutside
    },
    mounted() {
        this.value = moment(this.date);
    },
    computed: {
        theDate: {
            get() {
                return this.value
            },
            set(val) {
                this.value = val;
                this.$emit('changed', val)
            }
        }
    },
    methods: {
        dblClicked() {
            this.showDatePicker = true
        },
        cancel() {
            this.showDatePicker = false
        },
        formatDate(val) {
            return moment(val).format('DD-MM-YYYY');
        },
        clickedOut(e) {
            //console.log(e)
            if(!e.target.classList.value.includes('ant-calendar-')) {
                this.showDatePicker = false;
            }
            //this.showDatePicker = false;
        },
        updateDate() {
            this.loading = true;
            const d = this.theDate;
            const newDate = moment(this.theDate, 'DD-MM-YYYY').utc().toISOString();
            const id = this.id
            this.$http.post("https://"+url+"/api/dispatch/po/overdue/update", {
                id,
                date: newDate
            }).then(async resp => {
                if(resp.data.success) {
                    this.$message.info("Updated Expected Delivery Date");
                    this.showDatePicker = false;
                    this.value = moment(d);
                    console.log(this.theDate)
                } else {
                    this.$message.error("Failed");
                }
            }).catch(() => {
                this.$message.error("Failed");
            }).finally(() => {
                this.loading = false;
            })
          //this.$emit('update', this.theDate)
        }
    }
});
</script>
