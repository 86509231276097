<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
        </div>

        <div class="content bg-white shadow rounded mt-2 p-3 h-full">
            <div class="flex items-center">
                <a-button-group class="mr-3">
                    <a-button :type="type === 'open' ? 'primary' : 'default'" @click="type = 'open'">Open</a-button>
                    <a-button :type="type === 'partial' ? 'primary' : 'default'" @click="type = 'partial'">Partial</a-button>
                    <a-button :type="type === 'dsd_open' ? 'primary' : 'default'" @click="type = 'dsd_open'">DSD Open</a-button>
                    <a-button :type="type === 'dsd_partial' ? 'primary' : 'default'" @click="type = 'dsd_partial'">DSD Partial</a-button>
                </a-button-group>
                <a-button class="flex items-center justify-center" type="primary" @click="refresh" :loading="refreshing">
                    <template #icon>
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-refresh" width="18" height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
                            <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
                        </svg>
                    </template>
                </a-button>
                <a-input-search class="ml-auto w-48" v-model:value="searchTerm" placeholder="Search SKU..." :loading="searchLoading" @search="SearchData"/>
            </div>

            <div v-if="type==='open'">
                <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div class="inline-block min-w-full border border-gray-200 rounded-lg overflow-hidden">
                        <a-table :columns="columns" :data-source="openOrders" :pagination="false" :loading="loading" :rowKey="record => record.ExternalInvoiceNumber" :row-selection="{ selectedRowKeys: ooSelectedRowKeys, onChange: onOOSelectChange }" @expand="(event, record) => didExpand(event,record, 'openOrders')" bordered>
                            <template #expDelDate="{ record }">
                                <DateChange :date="record.QuotedDeliveryDate" :id="record.pkPurchaseID"></DateChange>
                            </template>

                            <template #expandedRowRender="{ record }">
                                <template v-if="record.po">
                                    <div class="border-l border-t border-r border-gray-300 rounded overflow-hidden">
                                        <table class="w-full">
                                            <thead>
                                            <tr class="bg-gray-100">
                                                <th class="px-2 py-1 border-b border-r border-gray-300">SKU</th>
                                                <th class="px-2 py-1 border-b border-r border-gray-300">Title</th>
                                                <th class="px-2 py-1 border-b border-r border-gray-300">Qty</th>
                                                <th class="px-2 py-1 border-b border-gray-300">Binrack</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in record.po.PurchaseOrderItem" :key="item.pkPurchaseItemId">
                                                <td class="px-2 py-1 border-r border-b border-gray-300">{{item.SKU}}</td>
                                                <td class="px-2 py-1 border-r border-b border-gray-300">{{item.ItemTitle}}</td>
                                                <td class="px-2 py-1 border-r border-b border-gray-300">{{item.Quantity}}</td>
                                                <td class="px-2 py-1 border-b border-gray-300">{{item.BinRack}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </template>
                                <div v-else class="w-full h-full flex items-center justify-center"><a-spin /></div>
                            </template>
                        </a-table>
                    </div>
                </div>
            </div>

            <div v-if="type==='partial'">
                <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div class="inline-block min-w-full border border-gray-200 rounded-lg overflow-hidden">
                        <a-table :columns="columns" :data-source="partialOrders" :pagination="false" :loading="loading" :rowKey="record => record.ExternalInvoiceNumber" :row-selection="{ selectedRowKeys: poSelectedRowKeys, onChange: onPOSelectChange }" @expand="(event, record) => didExpand(event,record, 'partialOrders')" bordered>
                            <template #expDelDate="{ record }">
                                <DateChange :date="record.QuotedDeliveryDate" :id="record.pkPurchaseID"></DateChange>
                            </template>

                            <template #expandedRowRender="{ record }">
                                <template v-if="record.po">
                                    <div class="border-l border-t border-r border-gray-300 rounded overflow-hidden">
                                        <table class="w-full">
                                            <thead>
                                            <tr class="bg-gray-100">
                                                <th class="px-2 py-1 border-b border-r border-gray-300">SKU</th>
                                                <th class="px-2 py-1 border-b border-r border-gray-300">Title</th>
                                                <th class="px-2 py-1 border-b border-r border-gray-300">Qty</th>
                                                <th class="px-2 py-1 border-b border-gray-300">Binrack</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in record.po.PurchaseOrderItem" :key="item.pkPurchaseItemId">
                                                <td class="px-2 py-1 border-r border-b border-gray-300">{{item.SKU}}</td>
                                                <td class="px-2 py-1 border-r border-b border-gray-300">{{item.ItemTitle}}</td>
                                                <td class="px-2 py-1 border-r border-b border-gray-300">{{item.Quantity}}</td>
                                                <td class="px-2 py-1 border-b border-gray-300">{{item.BinRack}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </template>
                                <div v-else class="w-full h-full flex items-center justify-center"><a-spin /></div>
                            </template>
                        </a-table>
                    </div>
                </div>
            </div>

            <div v-if="type==='dsd_open'">
                <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div class="inline-block min-w-full border border-gray-200 rounded-lg overflow-hidden">
                        <a-table :columns="columns" :data-source="dsdOpenOrders" :pagination="false" :loading="loading" :rowKey="record => record.ExternalInvoiceNumber" :row-selection="{ selectedRowKeys: dsdooSelectedRowKeys, onChange: onDSDPOSelectChange }" @expand="(event, record) => didExpand(event,record, 'dsdOpenOrders')" bordered>
                            <template #expDelDate="{ record }">
                                <DateChange :date="record.QuotedDeliveryDate" :id="record.pkPurchaseID"></DateChange>
                            </template>
                            <template #expandedRowRender="{ record }">
                                <template v-if="record.po">
                                    <div class="border-l border-t border-r border-gray-300 rounded overflow-hidden">
                                        <table class="w-full">
                                            <thead>
                                            <tr class="bg-gray-100">
                                                <th class="px-2 py-1 border-b border-r border-gray-300">SKU</th>
                                                <th class="px-2 py-1 border-b border-r border-gray-300">Title</th>
                                                <th class="px-2 py-1 border-b border-r border-gray-300">Qty</th>
                                                <th class="px-2 py-1 border-b border-gray-300">Binrack</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in record.po.PurchaseOrderItem" :key="item.pkPurchaseItemId">
                                                <td class="px-2 py-1 border-r border-b border-gray-300">{{item.SKU}}</td>
                                                <td class="px-2 py-1 border-r border-b border-gray-300">{{item.ItemTitle}}</td>
                                                <td class="px-2 py-1 border-r border-b border-gray-300">{{item.Quantity}}</td>
                                                <td class="px-2 py-1 border-b border-gray-300">{{item.BinRack}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </template>
                                <div v-else class="w-full h-full flex items-center justify-center"><a-spin /></div>
                            </template>
                        </a-table>
                    </div>
                </div>
            </div>

            <div v-if="type==='dsd_partial'">
                <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div class="inline-block min-w-full border border-gray-200 rounded-lg overflow-hidden">
                        <a-table :columns="columns" :data-source="dsdPartialOrders" :pagination="false" :loading="loading" :rowKey="record => record.ExternalInvoiceNumber" :row-selection="{ selectedRowKeys: dsdpoSelectedRowKeys, onChange: onDSDPOSelectChange }" @expand="(event, record) => didExpand(event,record, 'dsdPartialOrders')" bordered>
                            <template #expDelDate="{ record }">
                                <DateChange :date="record.QuotedDeliveryDate" :id="record.pkPurchaseID"></DateChange>
                            </template>
                            <template #expandedRowRender="{ record }">
                                <template v-if="record.po">
                                    <div class="border-l border-t border-r border-gray-300 rounded overflow-hidden">
                                        <table class="w-full">
                                            <thead>
                                            <tr class="bg-gray-100">
                                                <th class="px-2 py-1 border-b border-r border-gray-300">SKU</th>
                                                <th class="px-2 py-1 border-b border-r border-gray-300">Title</th>
                                                <th class="px-2 py-1 border-b border-r border-gray-300">Qty</th>
                                                <th class="px-2 py-1 border-b border-gray-300">Binrack</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in record.po.PurchaseOrderItem" :key="item.pkPurchaseItemId">
                                                <td class="px-2 py-1 border-r border-b border-gray-300">{{item.SKU}}</td>
                                                <td class="px-2 py-1 border-r border-b border-gray-300">{{item.ItemTitle}}</td>
                                                <td class="px-2 py-1 border-r border-b border-gray-300">{{item.Quantity}}</td>
                                                <td class="px-2 py-1 border-b border-gray-300">{{item.BinRack}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </template>
                                <div v-else class="w-full h-full flex items-center justify-center"><a-spin /></div>
                            </template>
                        </a-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, nextTick} from "vue";
import moment from 'moment'
import DateChange from "../components/DateChange.vue";
const url = "api.darkside-developments.com" //"darkside_api.test";
export default defineComponent({
    name: "OverduePO",
    components: {DateChange},
    data() {
        return {
            searchTerm: "",
            searchLoading: false,
            refreshing: false,
            allSuppliers: [],
            type: "open",
            loading: false,
            openOrders: [],
            partialOrders: [],
            dsdPartialOrders: [],
            dsdOpenOrders: [],
            columns: [],
            ooSelectedRowKeys: [],
            poSelectedRowKeys: [],
            dsdpoSelectedRowKeys: [],
            dsdooSelectedRowKeys: [],
            availableSuppliers: []
        }
    },
    mounted() {
        this.GetData();
    },
    methods: {
        didExpand(didExpand, record, type) {
            if(didExpand === true) {
                const pos = this[type];
                const indx = pos.findIndex(el => el.pkPurchaseID === record.pkPurchaseID);
                if (indx > -1) {
                    if(!pos[indx].po) {
                        console.log('no po')
                        this.$http.get("https://"+url+"/api/dispatch/po?id=" + record.pkPurchaseID).then(async resp => {
                            pos[indx].po = resp.data;
                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                }
            }
        },
        formatDate(val) {
            return moment(val).format('DD-MM-YYYY');
        },
        refresh() {
            this.searchTerm = "";
            this.refreshing = true;
            this.GetData();
        },
        GetData() {
            this.allSuppliers = [];
            this.loading = true;
            this.$http.get("https://"+url+"/api/dispatch/po/overdue").then(async resp => {
                const arr = resp.data.open.concat(resp.data.partial);
                const arr2 = resp.data.dsd_partial.concat(resp.data.dsd_open);
                const arr3 = arr.concat(arr2);
                console.log(arr3)
                const suppliers = [];
                for (const el of arr3) {
                    const find = suppliers.find(e => e.text === el.SupplierName);
                    if(!find) {
                        suppliers.push({
                            text: el.SupplierName,
                            value: el.SupplierName
                        })
                    }
                    //const find = this.allSuppliers.find(e => e.text === el.SupplierName);
                    //if(!find && el.SupplierName !== null) {
                    //    this.allSuppliers.push({
                    //        text: el.SupplierName,
                    //        value: el.SupplierName
                    //    })
                    //}
                    //if(find) {
                    //    const find2 = this.availableSuppliers.filter(e => e.text === el.SupplierName);
                    //    if(find2.length === 0) {
                    //        this.availableSuppliers.push({
                    //            text: el.SupplierName,
                    //            value: el.SupplierName
                    //        })
                    //    }
                    //}

                }
                //this.availableSuppliers = this.availableSuppliers.sort((a, b) => a.text.localeCompare(b.text))
                this.availableSuppliers = suppliers.sort((a, b) => a.text.localeCompare(b.text))
                this.columns = [
                    {
                        title: 'PO',
                        key: 'ExternalInvoiceNumber',
                        dataIndex: 'ExternalInvoiceNumber',
                        sorter: (a, b) => {
                            if(a.ExternalInvoiceNumber < b.ExternalInvoiceNumber) { return -1; }
                            if(a.ExternalInvoiceNumber > b.ExternalInvoiceNumber) { return 1; }
                            return 0;
                        },
                        sortDirections: ['descend', 'ascend'],

                    },
                    {
                        title: 'Supplier',
                        key: 'SupplierName',
                        dataIndex: 'SupplierName',
                        sorter: (a, b) => {
                            if(a.SupplierName < b.SupplierName) { return -1; }
                            if(a.SupplierName > b.SupplierName) { return 1; }
                            return 0;
                        },
                        sortDirections: ['descend', 'ascend'],
                        filters: this.availableSuppliers,
                        onFilter: (value, record) => record.SupplierName.indexOf(value) === 0,
                    },
                    {
                        title: 'Status',
                        key: 'Status',
                        dataIndex: 'Status',
                        sorter: (a, b) => {
                            if(a.Status < b.Status) { return -1; }
                            if(a.Status > b.Status) { return 1; }
                            return 0;
                        },
                        sortDirections: ['descend', 'ascend'],
                    },
                    {
                        title: 'Purchase Date',
                        key: 'DateOfPurchase',
                        dataIndex: 'DateOfPurchase',
                        sorter: (a, b) => new moment(a.DateOfPurchase).format('YYYYMMDDHHmmss') - new moment(b.DateOfPurchase).format('YYYYMMDDHHmmss'),
                        sortDirections: ['descend', 'ascend'],
                        customRender: (a) => {
                            return moment(a.record.DateOfPurchase).format('DD-MM-YYYY')
                        }
                    },
                    {
                        title: 'Expected Delivery Date',
                        key: 'QuotedDeliveryDate',
                        dataIndex: 'QuotedDeliveryDate',
                        sorter: (a, b) =>  new moment(a.QuotedDeliveryDate).format('YYYYMMDDHHmmss') - new moment(b.QuotedDeliveryDate).format('YYYYMMDDHHmmss'),
                        sortDirections: ['descend', 'ascend'],
                        //customRender: (a) => {
                        //    return moment(a.record.QuotedDeliveryDate).format('DD-MM-YYYY HH:mm:ss')
                        //},
                        slots: {
                            customRender: 'expDelDate',
                        }
                    }
                ]
                await nextTick();
                this.openOrders = resp.data.open
                this.partialOrders = resp.data.partial
                this.dsdPartialOrders = resp.data.dsd_partial
                this.dsdOpenOrders = resp.data.dsd_open
                this.loading = false;
                this.refreshing = false;
            })
        },
        SearchData() {
            if(this.searchTerm === "") this.getData();
            this.searchLoading = true;
            this.allSuppliers = [];
            this.loading = true;
            this.$http.get("https://"+url+"/api/dispatch/po/overdue/search?value=" + this.searchTerm).then(async resp => {
                const arr = resp.data.open.concat(resp.data.partial);
                const arr2 = resp.data.dsd_partial.concat(resp.data.dsd_open);
                const arr3 = arr.concat(arr2);
                for (const el of arr3) {
                    const find = this.allSuppliers.find(e => e.text === el.SupplierName);
                    if(!find && el.SupplierName !== null) {
                        this.allSuppliers.push({
                            text: el.SupplierName,
                            value: el.SupplierName
                        })
                    }
                }
                this.columns = [
                    {
                        title: 'PO',
                        key: 'ExternalInvoiceNumber',
                        dataIndex: 'ExternalInvoiceNumber',
                        sorter: (a, b) => {
                            if(a.ExternalInvoiceNumber < b.ExternalInvoiceNumber) { return -1; }
                            if(a.ExternalInvoiceNumber > b.ExternalInvoiceNumber) { return 1; }
                            return 0;
                        },
                        sortDirections: ['descend', 'ascend'],

                    },
                    {
                        title: 'Supplier',
                        key: 'SupplierName',
                        dataIndex: 'SupplierName',
                        sorter: (a, b) => {
                            if(a.SupplierName < b.SupplierName) { return -1; }
                            if(a.SupplierName > b.SupplierName) { return 1; }
                            return 0;
                        },
                        sortDirections: ['descend', 'ascend'],
                        filters: this.allSuppliers,
                        onFilter: (value, record) => record.SupplierName.indexOf(value) === 0,
                    },
                    {
                        title: 'Status',
                        key: 'Status',
                        dataIndex: 'Status',
                        sorter: (a, b) => {
                            if(a.Status < b.Status) { return -1; }
                            if(a.Status > b.Status) { return 1; }
                            return 0;
                        },
                        sortDirections: ['descend', 'ascend'],
                    },
                    {
                        title: 'Purchase Date',
                        key: 'DateOfPurchase',
                        dataIndex: 'DateOfPurchase',
                        sorter: (a, b) => new moment(a.DateOfPurchase).format('YYYYMMDDHHmmss') - new moment(b.DateOfPurchase).format('YYYYMMDDHHmmss'),
                        sortDirections: ['descend', 'ascend'],
                        customRender: (a) => {
                            return moment(a.record.DateOfPurchase).format('DD-MM-YYYY HH:mm:ss')
                        }
                    },
                    {
                        title: 'Expected Delivery Date',
                        key: 'QuotedDeliveryDate',
                        dataIndex: 'QuotedDeliveryDate',
                        sorter: (a, b) =>  new moment(a.QuotedDeliveryDate).format('YYYYMMDDHHmmss') - new moment(b.QuotedDeliveryDate).format('YYYYMMDDHHmmss'),
                        sortDirections: ['descend', 'ascend'],
                        customRender: (a) => {
                            return moment(a.record.QuotedDeliveryDate).format('DD-MM-YYYY HH:mm:ss')
                        },
                        slots: {
                            customRender: 'expDelDate',
                        }
                    },
                ]
                await nextTick();
                this.openOrders = resp.data.open
                this.partialOrders = resp.data.partial
                this.dsdPartialOrders = resp.data.dsd_partial
                this.dsdOpenOrders = resp.data.dsd_open
                this.loading = false;
                this.searchLoading = false;
            })
        },
        onOOSelectChange(selectedRowKeys) {
            this.ooSelectedRowKeys = selectedRowKeys;
        },
        onPOSelectChange(selectedRowKeys) {
            this.poSelectedRowKeys = selectedRowKeys;
        },
        onDSDOOSelectChange(selectedRowKeys) {
            this.dsdooSelectedRowKeys = selectedRowKeys;
        },
        onDSDPOSelectChange(selectedRowKeys) {
            this.dsdpoSelectedRowKeys = selectedRowKeys;
        },
        updateExpectedDeliveryDate(date, record) {
            const newDate = moment(date, 'DD-MM-YYYY').utc().toISOString();
            const id = record.pkPurchaseID
            this.$http.post("https://"+url+"/api/dispatch/po/overdue/update", {
                id,
                date: newDate
            }).then(async resp => {
                if(resp.data.success) {
                    this.$message.info("Updated Expected Delivery Date");
                } else {
                    this.$message.error("Failed");
                }
            }).catch(() => {
                this.$message.error("Failed");
            })
        }
    },
    filters: {
        formatDate(val) {
            return moment(val).format('DD-MM-YYYY')
        }
    }
});
</script>

<style lang="scss" scoped>
.ant-table-wrapper {
    background: white;
}
.ant-table-thead tr th{
    @apply px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider;
}
tr.ant-table-row td {
    @apply px-5 py-5 border-b border-gray-200 bg-white text-sm;
}
.ant-pagination.ant-table-pagination {
    padding-right: 20px;
}
</style>
